/* styles.css */
.floating-button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    background-color: #3f51b5; /* Primary color for the button (you can change this) */
    color: #fff; /* Text color for the button */
    border-radius: 50%;
    width: 56px; /* Set the width and height to create a circular button */
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.floating-button:hover {
    background-color: #303f9f; /* Change the background color on hover (you can adjust this) */
}