*,*::after,*::before{
    color: #333;
    box-sizing: border-box;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}


.body{
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 1100px;
    border-radius: 5px 20px 0px 30px;
    background-image: repeating-linear-gradient(
      45deg,
      rgba(0, 191, 255, 0.5),
      rgba(0, 191, 255, 0.5) 1px,
      transparent 1px,
      transparent 15px
    ),
    repeating-linear-gradient(
      -45deg,
      rgba(255, 105, 180, 0.5),
      rgba(255, 105, 180, 0.5) 1px,
      transparent .5px,
      transparent 15px
    );
    background-size: 10px 10px;
    background-color: #eaeaea;   
}

.sidebar{
    display: flex;
    flex-direction: column;
    background: radial-gradient(circle farthest-side, 
    #aaa 30%, #cecece 30% 60%, #aaa 30%);
    box-sizing: border-box;
    width: 200px;
    height: 100%;
    padding: 20px;
    border-radius: 5px 0 20px 30px;
    position: relative;
    font-size: 15px;
    padding-top: 70px;

}

.school-logo {
    position: absolute;
    width: 100px;
    position: absolute;
    width: 100px;
    margin-left: 40px;
    
}
.student-image {
    position: absolute;
    width: 70px;
    margin-left: 40px;
    margin-top: 90px;
    
}

img {
    width: 70px;
    height:75px;
    border: 5px solid grey;
    padding: 3px;
    border-radius: 5px;
    
}
.student-name {
    margin-top: 155px;
    font-weight: 700;
    
}

.qrcd {  
    width: 70px;
    margin-left: 40px; 
}

.header {
   
    width: 900px;
    padding: 2px;
    text-align: center;
    background:radial-gradient(circle farthest-side, 
    #aaa 30%, #cecece 30% 60%, #aaa 30%);
    
}

.the-school-name {
    font-size: 30px;
    font-weight: bolder;
}

.address {
    margin-top: -20px;
    line-height: 15px;
}

.school-add {
    font-size: 20px;
    font-weight: 600;
}

.school-email, .school-web {
    font-size: 19px;
    margin-top: -10px;

}

/*-------------------------------------- Table for subjects----------------------------*/


.headerss {
    background-color: #cecece;
    width: 200px;
    text-align: center;
    margin-left: 300px;
    line-height: 10px;
}

.headers {
    font-size: 20px;
    font-variant:small-caps;
    font-weight: bold;
}
.table {
    width: 700px;
    margin-left: 100px;
    max-height: 100%;
    
}

table {
    border-collapse: collapse;
    border: 1px solid black;
    background-color: #e0e0e0;
   
}

tr:nth-child(even) {
    background-color: rgb(207, 168, 168);
}

 tr td {
    border: 1px solid black;
   
}

th:nth-child(even) {
    background-color: rgb(207, 168, 168);
}

.tboby tr td{
  padding: 6px;
  width: 30px;
  height: 30px; 
    
}
.tboby {
    width: 750px;
    margin-left: -60px;
    
}

.apart-from-side-bar {
    display: flex;
    flex-direction: column;
}

.behavioural-assessment {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 300px;
    margin-left: 20px;
    text-align: center;
}

.Behavioural, .Key {
    font-weight: bold;
}

.table2 tr td {
    padding: 2px;
}

.key-to-grade {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-top: -110px;
    text-align: center;  
    margin-left: 350px; 
    margin-top: -300px;
}

.key-to table {
    width: 200px;
    height: 200px;
}

.Key {
    margin-left: -100px;
}

.flex-comment {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 5px;
    padding: 12px 14px;
}

.teacher-comment,.teacher-name{
    background-color: rgb(240, 238, 255);
    width: 200px;
    font-weight: bold;
}

.sign{
    background-color: #fff;
    width: 80px;
    font-weight: bold;
}
.class-teacher-comment {
    background-color: #cecece;
    margin-top: 5px;
    margin-right: 5px;
    width: 870px;
    display: inline; 
    position: relative;
   
}

.head-teacher-comment{
    position: relative;
    background-color: #cecece;
    margin-top: 5px;
    margin-right: 5px;
    width: 650px;
    display: inline; 
}

.class-teacher-name{
    background-color: #cecece;
    margin-top: 5px;
    margin-right: 5px;
    width: 650px;
    display: inline; 
    position: relative;
}

.signature {
    background-color: #cecece;
    margin-top: 5px;
    margin-right: 5px;
    width: 214px;
    display: inline; 
    position: relative;
}

.payable-fees {
    width: 300px;
    position: inherit;
    margin-left: 76%;
    margin-top: -230px;
    border: 5px solid rgb(78, 78, 78);
    font-size: 15px;
    height: auto;
    line-height: 15px;
    border-radius: 10px;
    background-color: #cecece;
}

.fees,.pay,.sess-sect {
    font-size: 15px;
    line-height: 10px;
    text-align: center;
    font-weight: 600;
}

.comment {
    position: relative;
}
.comment::after {
    position: absolute;
    content: '___________________________________';
    right: 89px;
    height: 2px;
    width: 200px;
    

}

.comments {
    position: relative;
    z-index: 1;
}

.comments::after {
    position: absolute;
    content: '_________________________________________________________________________________________________';
    top: -20px;
    height: 2px;
    width: 200px;
    

}

.commentss {
    position: relative;
    z-index: 1;
}

.commentss::after {
    position: absolute;
    content: '_________________________________________________________________________';
    top: -20px;
    height: 2px;
    width: 200px;
    

}

.commentsss {
    position: relative;
    z-index: 1;
}

.commentsss::after {
    position: absolute;
    content: '________________________';
    top: -20px;
    height: 2px;
    width: 200px;
    

}



